import { useCallback, useEffect, useState } from 'react'

import {
  Box,
} from '@chakra-ui/react'

interface Props {}

const Profile = ({ }: Props) => {
  return (
    <Box>Profile</Box>
  )
};

export default Profile;
