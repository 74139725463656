export const regions = [
  "Any Region",
  "Austin",
  "Chicago",
  "Houston",
  "Los Angeles",
  "New York City",
  "Paris",
  "San Francisco",
  "Santa Clara Valley",
  "Sao Paolo",
  "Seattle",
  "Sydney",
  "Toronto",
]