import { useCallback, useEffect, useState } from 'react'

import {
  Box,
} from '@chakra-ui/react'

interface Props {}

const Settings = ({ }: Props) => {
  return (
    <Box>Settings</Box>
  )
};

export default Settings;
